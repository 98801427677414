import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { page } from "components/page";
import SearchBlock from "components/search/SearchBlock";
import { autobind } from "react-decoration";
import ReactHtmlParser from "react-html-parser";

@withI18next(["common"])
@page
class Privacy extends React.Component {
  constructor(props) {
    super(props);
    let state = this.getQueryState(props);
    this.state = state;
  }

  componentWillReceiveProps(props) {
    if (this.props !== props) {
      this.setQueryState(props);
    }
  }

  @autobind
  setQueryState(props) {
    let state = this.getQueryState(props);
    if (JSON.stringify(state) !== JSON.stringify(this.state)) {
      this.setState({
        ...state,
      });
    }
  }

  @autobind
  getQueryState(props) {
    let { search, readerStore } = props;
    let params = { ...search };
    let { pageNo, limit, resourceType, newsType } = params;

    pageNo = pageNo || 1;
    limit = limit || 10;

    newsType = newsType || "local";

    let searchForm = {
      pageNo,
      limit,
      resourceType,
      newsType,
    };

    Object.keys(searchForm).map((key) => {
      if (
        searchForm[key] === null ||
        (typeof searchForm[key] === "string" && searchForm[key].trim() === "")
      ) {
        delete searchForm[key];
      }
    });

    let state = {
      newsType: newsType,
      searchForm: searchForm,
      auth: readerStore.auth,
      resourceType: resourceType,
    };
    return state;
  }

  render() {
    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <SearchBlock />
          <section className="main_content">
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accesskey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <h2>{this.props.t("jumperrwd.privacy.title")}</h2>
              {ReactHtmlParser(this.props.t("jumper.common.privacy.content"))}
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Privacy;
